<template>
  <div class="smartHr">
    <div style="overflow-y: scroll;height:100%;width:100%" ref="GoTopContent">
      <Title></Title>

      <div class="banner">
        <h4 style="font-size: 46px; font-weight: 600; padding: 0px 0 40px">
          学术能力智慧评估
        </h4>
        <button @click="$router.push('/freeTrial')">立即预约演示</button>
      </div>

      <div class="systemArchitecture">
        <h4 class="descH4">应用框架</h4>
        <span class="line"></span>
        <img src="@/assets/solute/smartXsnl/gnt.png" alt="" style="width:1020px;margin-top:40px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">业务痛点</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in valueList" :key="index">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeft">
            <img :src="item.hoverScr" alt="" class="valueLeftHover">
            <div class="valueRight">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <div class="solute">
        <h4 class="descH4">解决方案</h4>
        <span class="line"></span>
        <img src="@/assets/solute/smartXsnl/cjfw.png" alt="" style="width:900px;margin-top:40px">
      </div>

      <div class="systemValue">
        <h4 class="descH4">评估方案</h4>
        <span class="line"></span>
        <div class="valueContent">
          <div class="valueItem" v-for="(item,index) in pgfaList" :key="index">
            <div class="valueItemMask"></div>
            <img :src="item.src" alt="" class="valueLeft">
            <img :src="item.hoverScr" alt="" class="valueLeftHover">
            <div class="valueRight">
              {{item.content}}
            </div>
          </div>
        </div>
      </div>

      <div class="applicationCase">
        <h4 class="descH4">应用案例</h4>
        <span class="line"></span>
        <div class="appCaseCon">
          <el-carousel indicator-position="outside" class="caseContent" arrow="never">
            <el-carousel-item v-for="(item,index) in caseData" :key="index" class="caseItem">
              <div class="caseLeft">
                <img :src="item.src" alt="" style="width:100%;height:100%">
              </div>
              <div class="caseRight">
                <div class="caseTitle">{{item.title}}</div>
                <div class="caseRIghtCon">{{item.content}}</div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <Foot></Foot>
    </div>
    <BackTop class="backTop"></BackTop>
  </div>
</template>

<script>
export default {
  name: 'SmartXsnl',
  data () {
    return {
      valueList: [
        { content: '手工整理材料，文档资料多', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '材料打印工作量大，线下送审难', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '线下审阅材料，评审工作量大，易出错', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '人工跟进，信息容易滞后，耗时长', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ],
      caseData: [
        { src: require('@/assets/solute/smartXsnl/shpiy.png'), title: '上海教育评估院', content: '上海市高校教师学术能力同行评议平台是在整体规划的指导下，从“智能技术、灵活配置、创新管理”三个层面提升上海教育评估院对全市各校教师学术能力同行评议的管理与服务能力，一方面，通过平台建立一套科学可行的同行评议机制，搭建一个信息丰富、流程灵活、数据分析智能、交互体验友好、评估科学的高校教师学术能力同行评议平台，同时打造一个功能规划合理、性能安全稳定、管理应用便捷的信息化支撑环境，推动高校学术鉴定管理工作实现“降本增效、精准管理”的发展目标，以此保证同行评议的公平公正和规范性。' }
      ],
      pgfaList: [
        { content: '全流程平台化管理，将各环节申报人、送审单位、评估中心、同行专家无缝链接在一起，评审过程全程留痕，确保评审过程合规、公正、可溯。', src: require('@/assets/solute/SmartCampus/smartOne.png'), hoverScr: require('@/assets/oneHover.png') },
        { content: '评审资料在线数字化，支持在线预览与下载，自动匹配专家，方便邀请专家评审和反馈。在线定制评审表单，满足高校个性化需求。', src: require('@/assets/solute/SmartCampus/smartTwo.png'), hoverScr: require('@/assets/towHover.png') },
        { content: '全程跟踪，实时监控专家评审进度，多维度评审数据分析，及时督促调整，确保整体进度可调控。', src: require('@/assets/solute/SmartCampus/smartThree.png'), hoverScr: require('@/assets/threeHover.png') },
        { content: '多维度专家价值评估，建立体系化的专家标签和行为标签，智能化专家洞察，对专家资源库进行智能化圈群分组。', src: require('@/assets/solute/SmartCampus/smartFour.png'), hoverScr: require('@/assets/fiveHover.png') }
      ]
    }
  },
  methods: {
    getHeight () {
      if (window.innerWidth <= 800) {
        this.$router.push('/moSlution/moAssess')
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  created () {
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  }
}
</script>

<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.smartHr{
  position: relative;
  top: 0;
  left: 0;
  min-width: 1200px;
  height: 100vh;
  overflow: hidden;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  object-fit: cover;
}
li {
  list-style: none;
}
.line {
  display: inline-block;
  width: 40px;
  height: 6px;
  background: #d70c0c;
  border-radius: 3px;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.banner {
  width: 100%;
  height: 460px;
  background: url("~@/assets/solute/smartXycg/banner.png") center no-repeat;
    background-size: cover;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.banner button {
  width: 200px;
  height: 60px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 16px;
  color: #fff;
  border: 0;
}
.banner button:hover {
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
  cursor: pointer;
}
.descH4 {
  font-size: 36px;
  font-weight: 500;
  color: #222222;
  padding: 60px 0 20px;
}

.systemArchitecture{
  width: 100%;
  height: 910px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.systemValue{
     width: 100%;
height: 576px;
background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
display: flex;
flex-direction: column;
align-items: center;
}

.valueContent{
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 40px;
}
.valueItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  height: 148px;
  margin-right: 40px;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 40px;
  padding: 40px;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.valueItem:nth-child(2n){
  margin-right: 0px;
}
.valueLeft{
  width: 68px;
}
.valueLeftHover{
  width: 68px;
  display: none;
}
.valueRight{
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  margin-left: 20px;
}
.valueItem:hover{
  box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .valueRight{
    color: #fff;
  }
  .valueLeftHover{
    display: block;
  }
  .valueLeft{
    display: none;
  }
}

.ywtd{
  width: 100%;
  height: 750px;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yetdTitleDes{
  width: 900px;
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 28px;
  text-align: center;
  margin-bottom: 20px;
}
.yetdContent{
  width: 1200px;
  margin-top:40px;
  display: flex;
  flex-wrap: wrap;
}
.yetdItem{
  position: relative;
  top: 0;
  left: 0;
  width: 580px;
  // min-height: 180px;
  // max-height: 220px;
  margin-right: 40px;
  margin-bottom: 40px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(220,223,230,0.5);
  padding: 31px 40px;
  box-sizing: border-box;
  cursor: pointer;
}
.valueItemMask{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.yetdItem:nth-child(2n){
  margin-right: 0px;
}
.yetdLine{
  width: 30px;
  height: 4px;
  background: #D70C0C;
  margin: 14px 0 16px;
}
.yetdTitle{
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 36px;
}
.yetdCon{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 24px;
}
.yetdItem:hover{
   box-shadow: 0px 8px 20px 0px rgba(173,0,0,0.3);
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border: 0px;
  .valueItemMask{
    display: block;
    background: url('~@/assets/hoverBg.png') center no-repeat;
    background-position: top left;
  }
  .yetdTitle,.yetdCon{
    color: #fff;
  }
  .yetdLine{
    background-color: #fff;
  }
}

.solute{
  width: 100%;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.applicationCase{
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appCaseCon{
  height: 490px;
}
.caseContent{
  width: 1200px;
  min-height: 400px;
  padding-top: 60px;
}
.caseContent{
  &::v-deep{
    .el-carousel__container{
      height: 330px;
    }
  }
}

.caseItem{
  width: 100%;
  height: 330px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(211,218,228,0.5);
  cursor: pointer;
}
.caseItem:hover{
  box-shadow: 0px 4px 20px -4px rgba(0,0,0,0.15);
}
.caseLeft{
  width: 460px;
  height: 100%;
}
.caseRight{
  width: 740px;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
}
.appCaseCon{
  &::v-deep {
      .el-carousel__button {
      width: 20px;
      height: 6px;
      background: #d70c0c !important;
      border-radius: 3px;
    }
    .el-carousel__indicators--outside{
      margin-top: 30px;
    }
  }
}
.caseTitle{
  font-size: 24px;
  font-weight: 500;
  color: #222222;
  line-height: 28px;
}
.caseRIghtCon{
  font-size: 16px;
  font-weight: 400;
  color: #444444;
  line-height: 26px;
  padding-top: 12px;
}
</style>
